import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CatLinks from "../components/CatLinks"

const Categories = ({ pageContext, data }) => {
  //const { category } = pageContext
  //const { edges, totalCount } = data.allMarkdownRemark
  const textColour = (light) => (light ? '#4e4e4e' : '')
  return (
    <Layout>
      <SEO title="Projects" />
      <section className="projects-collection">
        <div className="container">
          <div className="projects-filter">
            <CatLinks />
          </div>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Link to={node.fields.slug} key={node.fields.slug + 'project'} >
              <div className="project-collection--project" style={{backgroundImage: 'url(' + node.frontmatter.featureImage.publicURL + ')'}}>
                <span className="hover-colour" style={{ backgroundColor: node.frontmatter.colour }}></span>
                <span className="project-title" style={{ color: textColour(node.frontmatter.light) }} >{node.frontmatter.title}</span>
                <span className="project-category" style={{ color: textColour(node.frontmatter.light) }} >{node.frontmatter.category}</span>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </Layout>
  )
}

Categories.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Categories

export const pageQuery = graphql`
  query($category: String) {
    allMarkdownRemark(
      sort: {fields: [frontmatter___order], order: ASC}
      filter: { frontmatter: { category: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            date
            order
            category
            featureImage {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 72) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
            colour
            light
          }
          fields {
            slug
          }
        }
      }
    }
  }
`


